<template>
	<div class="notice_popup view info">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="view-tit">회원개인정보</div>
		<div class="mem_count"><img src="/images/admin/logo_sm.png" alt="하이프로"/>회원번호<span class="count">{{param.mberSeq}}</span></div>
		<div class="view" v-if="data.mberSeq">
			<div class="list">
				<div class="label">성명</div><div class="contents">{{data.mberNm }}</div>
			</div>
			<div class="list">
				<div class="label">회원아이디</div><div class="contents">{{data.loginId }}</div>
			</div>
			<div class="list">
				<div class="label">휴대폰번호</div><div class="contents">{{data.hpNo | cell}}</div>
			</div>
			<div class="list">
				<div class="label">이메일</div><div class="contents">{{data.email}}</div>
			</div>
			<div class="list">
				<div class="label">이메일 수신여부</div><div class="contents">{{data.emailRecvYn == 'Y' ? '동의' : '미동의'}}</div>
			</div>
			<div class="list">
				<div class="label">SMS 수신여부</div><div class="contents">{{data.smsRecvYn == 'Y' ? '동의' : '미동의'}}</div>
			</div>
			<div class="list">
				<div class="label">회원소속사</div><div class="contents">{{data.corpNm}}</div>
			</div>
			<div class="list">
				<div class="label">직원구분</div><div class="contents"><SelectComp type="text" :value="data.empDivCd" cdId="PRO102" /></div>
			</div>
			<div class="list">
				<div class="label">생년월일</div><div class="contents"><template v-if="data.birthYyyymmdd">{{data.birthYyyymmdd | date('yyyy년 MM월 dd일')}}</template></div>
			</div>
			<div class="list">
				<div class="label">성별</div><div class="contents">{{data.gender | gender}}</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: { param:Object },
	data() {
		return {
			data: {},
		};
	},
	mounted(){
		this.$.httpPost('/api/mem/adm/prf/getMberInfo', {selectSeq:this.param.mberSeq})
			.then(res => {
				this.data = res.data;
				this.$emit('updated');
			}).catch(this.$.httpErrorCommon);
	},
	methods: {
	},
};
</script>
